import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { navigateToMembersArea } from '@wix/bookings-booking-navigation';

export type NavigateToMembersArea = () => void;

export function createNavigateToMembersAreaAction({
  context,
}: ActionFactoryParams<CalendarState, CalendarContext>): NavigateToMembersArea {
  return async () => {
    await navigateToMembersArea(context.flowAPI.controllerConfig.wixCodeApi);
  };
}
