import {
  getFormattedCurrency,
  getPaymentDescription as getPaymentDescriptionMapper,
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { CalendarContext } from '../context/contextFactory';
import { TFunction } from '../../components/BookingCalendar/controller';
import { SelectedVariantOptions } from '../../types/types';
import {
  Payment,
  RateType,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';

export const PRICE_DESCRIPTION_DELIMITER = ' | ';

export const getPaymentDescription = ({
  context,
  selectedVariantsOptions = [],
  service,
}: {
  context: CalendarContext;
  selectedVariantsOptions?: SelectedVariantOptions[];
  service: Service;
}) => {
  const paymentDescription = [];
  const {
    settings,
    settingsParams,
    isPricingPlanInstalled,
    languageSettings,
    t,
  } = context;
  const regionalSettingsLocale = languageSettings.regionalSettingsLocale!;

  const payment = service.payment;
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );

  if (isOfferedAsOneTime(payment!)) {
    let priceText = getServicePriceText(payment!, regionalSettingsLocale, t);

    if (
      payment?.rateType === RateType.VARIED &&
      selectedVariantsOptions.length > 0
    ) {
      const totalAmount = selectedVariantsOptions.reduce(
        (previousValue, { price, numberOfParticipants }) =>
          previousValue + Number(price.value!) * numberOfParticipants,
        0,
      );
      priceText = getFormattedCurrency({
        price: totalAmount,
        currency: selectedVariantsOptions[0].price.currency!,
        locale: regionalSettingsLocale,
      });
    }

    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan({ payment }) &&
    isPricingPlanInstalled &&
    isServiceConnectedToPricingPlan({ payment })
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return (
    paymentDescription
      // remove empty items
      .filter((priceItem) => !!priceItem.trim())
      .join(PRICE_DESCRIPTION_DELIMITER)
  );
};

export const isOfferedAsOneTime = (payment: Payment) => {
  return !!(payment.options?.inPerson || payment.options?.online);
};

export const getServicePriceText = (
  payment: Payment,
  regionalSettingsLocale: string,
  t: TFunction,
) => {
  const getPaymentDescriptionArgs: Parameters<
    typeof getPaymentDescriptionMapper
  >[0] = {
    payment,
    regionalSettingsLocale,
    paymentFormatters: {
      variedPrice: ({ minPrice }) =>
        t('payment.from-price', { price: minPrice }),
    },
  };

  return getPaymentDescriptionMapper(getPaymentDescriptionArgs);
};
