import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import {
  isLayoutWithTimeSlot,
  isWeeklyTimeSlotsLayout,
} from '../../../../utils/layouts';
import { TriggeredByOptions } from '../../../../types/types';
import { isSlotWithOpenWaitingList } from '../../../../utils/slotAvailability/slotAvailability';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { bookingsCalendarBookingDetailsLoad } from '@wix/bi-logger-wixboost-ugc/v2';

export type AutoSelectTime = (triggeredBy?: TriggeredByOptions) => void;

export function createAutoSelectTime(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedTime: SetSelectedTime,
): AutoSelectTime {
  return async (triggeredBy) => {
    const [state] = getControllerState();
    const { availableSlots } = state;
    const { settings, settingsParams, biLogger } = context;

    if (!isLayoutWithTimeSlot(settings, settingsParams)) {
      return;
    }

    const shouldSearchForSelectableSlot = !isWeeklyTimeSlotsLayout(
      settings,
      settingsParams,
    );
    const selectableSlot =
      shouldSearchForSelectableSlot && getSelectableSlot({ availableSlots });

    if (selectableSlot) {
      const selectedTimeTrigger =
        triggeredBy === TriggeredByOptions.INITIALIZE_WIDGET
          ? triggeredBy
          : TriggeredByOptions.TIME_AUTO_SELECTED;

      setSelectedTime(selectableSlot.slot?.startDate!, selectedTimeTrigger);
    } else if (triggeredBy === TriggeredByOptions.INITIALIZE_WIDGET) {
      void biLogger.report(
        bookingsCalendarBookingDetailsLoad({
          triggeredBy,
        }),
      );
    }
  };
}

const getSelectableSlot = ({
  availableSlots,
}: {
  availableSlots: CalendarState['availableSlots'];
}): SlotAvailability | undefined => {
  if (availableSlots?.availabilityEntries?.length) {
    const firstSlot = availableSlots.availabilityEntries?.[0];
    return firstSlot.bookable || isSlotWithOpenWaitingList(firstSlot)
      ? firstSlot
      : undefined;
  }
};
