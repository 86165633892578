import { TFunction } from '../../components/BookingCalendar/controller';
import {
  differencesBetweenTwoDates,
  TimeUnits,
} from '../dateAndTime/dateAndTime';
import {
  DurationMapper,
  DurationMapperOptions,
} from '@wix/bookings-uou-mappers';

export const getSlotDuration = ({
  rfcStartTime,
  rfcEndTime,
  t,
  dateRegionalSettingsLocale,
}: {
  rfcStartTime: string;
  rfcEndTime: string;
  t: TFunction;
  dateRegionalSettingsLocale: string;
}) => {
  const durationInMinutes = differencesBetweenTwoDates(
    rfcStartTime,
    rfcEndTime,
    TimeUnits.minute,
  );

  return getDurationFromMinutes({
    minutes: durationInMinutes,
    t,
    dateRegionalSettingsLocale,
  });
};

export const getDurationFromMinutes = ({
  minutes,
  t,
  dateRegionalSettingsLocale,
}: {
  minutes: number;
  t: TFunction;
  dateRegionalSettingsLocale: string;
}): { durationText: string; durationAriaText: string } => {
  const durationOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
  };

  const durationMapper = new DurationMapper(
    dateRegionalSettingsLocale,
    durationOptions,
    t,
  );

  return {
    durationText: durationMapper.durationTextFromMinutes(minutes),
    durationAriaText: durationMapper.durationAriaLabelFromMinutes(minutes),
  };
};
