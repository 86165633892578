import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { IWixAPI } from '@wix/yoshi-flow-editor';

export const extractQueryParamValues = ({
  wixCodeApi,
  queryParam,
}: {
  queryParam: BookingsQueryParams;
  wixCodeApi: IWixAPI;
}): string[] => {
  const queryParamValues = getUrlQueryParamValue(wixCodeApi, queryParam);

  if (queryParamValues) {
    const queryParamValuesArray = queryParamValues.split(',');
    return queryParamValuesArray.map((value) => value.trim());
  }

  return [];
};
