import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { withErrorHandler } from '@wix/bookings-viewer-error-handler';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Booking } from '@wix/bookings-checkout-api';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { bookingsCalendarErrorMessages } from '@wix/bi-logger-wixboost-ugc/v2';
import { FlowElements } from './Hooks/useFlow';
import { EmptyStateType } from './ViewModel/emptyStateViewModel/emptyStateViewModel';
import { CalendarStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import {
  CalendarViewModel,
  createMemoizedCalendarViewModelFactory,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CalendarApi } from '../../api/CalendarApi';
import {
  createControlledComponent,
  Render,
} from '../../utils/ControlledComponent/ControlledComponent';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { createInitialState } from '../../utils/state/initialStateFactory';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import {
  CalendarErrors,
  FilterOptions,
  Preset,
  SelectedVariantOptions,
  SlotsStatus,
  TriggeredByOptions,
} from '../../types/types';
import calendarSettingsParams from './settingsParams';
import { isCalendarPage, isCalendarWidget } from '../../utils/presets';
import type { ServicesPreferencesModalData } from '@wix/bookings-services-preferences-modal/types';
import {
  getUrlQueryParamValue,
  BookingsQueryParams,
  extractCalendarSelections,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
  isMemberAreaInstalled as isMemberAreaInstalledUtils,
  getServiceSlug,
  CALENDAR_PAGE_URL_PATH_PARAM,
  ReservedSlug,
  AUTO_OPEN_PREFERENCES_MODAL_STORAGE_KEY,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { isServiceWithDynamicDuration } from '@wix/bookings-calendar-catalog-viewer-mapper/domain';
import { redirectToDateAndTimeViewMode } from '../../utils/redirectToDateAndTimeViewMode/redirectToDateAndTimeViewMode';
import { bookingsUoUFlowWidgetLoadSrc16Evid32 } from '@wix/bi-logger-bookings-data/v2';
import { PresetToWidgetNameMap } from '../../utils/bi/consts';
import { withBookingsViewerCache } from '@wix/bookings-viewer-cache';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  calendarStatus: CalendarStatus;
  slotsStatus: SlotsStatus;
  servicesInView: Service[];
  selectedDate?: string;
  selectedDateTrigger?: TriggeredByOptions;
  selectedTimezone?: string;
  selectedRange?: {
    from: string;
    to: string;
  };
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
  initialErrors: EmptyStateType[];
  purchasedPricingPlans: Plan[];
  isUserLoggedIn: boolean;
  selectedVariantsOptions: SelectedVariantOptions[];
  serviceVariantsMap: Record<string, ServiceOptionsAndVariants>;
  servicesPreferencesModalData?: ServicesPreferencesModalData;
};

export const createControllerFactory = (
  preset: Preset,
  settingsParams: any,
) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
  }: ControllerParams) => {
    if (flowAPI.experiments.enabled('specs.bookings.useBookingsViewerCache')) {
      withBookingsViewerCache(flowAPI);
    }

    const { controllerConfig, experiments, httpClient, errorHandler } = flowAPI;

    if (flowAPI.experiments.enabled('specs.bookings.withErrorHandler')) {
      withErrorHandler({ httpClient, errorHandler });
    }

    const isCalendarPagePreset = isCalendarPage(preset);
    const isCalendarWidgetPreset = isCalendarWidget(preset);
    const subscriptions: CalendarContext['subscriptions'] = {};
    let rerender: Render<CalendarState> = async () => {};
    let publicData = controllerConfig.config.publicData.COMPONENT || {};
    let calendarContext: CalendarContext;
    let initialState: CalendarState;
    let servicesInView: Service[];
    let pageReady: () => Promise<void>;

    // eslint-disable-next-line prefer-const
    pageReady = async () => {
      const isUseBookingsViewerCacheEnabled = flowAPI.experiments.enabled(
        'specs.bookings.useBookingsViewerCache',
      );

      flowAPI.fedops.interactionStarted(
        isUseBookingsViewerCacheEnabled
          ? 'calendar-pageReady-useBookingsViewerCache-true'
          : 'calendar-pageReady-useBookingsViewerCache-false',
      );

      const { reportError } = flowAPI;

      const calendarApi = new CalendarApi({
        reportError,
        flowAPI,
        settingsParams,
        preset,
      });

      const initialErrors: EmptyStateType[] = [];

      const onError = (type: EmptyStateType) => initialErrors.push(type);

      const isAnonymousCancellationFlow =
        getUrlQueryParamValue(
          flowAPI.controllerConfig.wixCodeApi,
          BookingsQueryParams.REFERRAL,
        ) === 'batel';
      const selectedService = flowAPI.settings.get(
        settingsParams.selectedService,
      );
      const shouldUseCalendarDummyViewModel =
        flowAPI.environment.isEditor && selectedService === '';
      const currentUser = controllerConfig.wixCodeApi.user.currentUser;
      const isDateAndTimeViewMode = await isDateAndTimeMode({
        flowAPI,
        preset,
      });
      const calendarSelections = isDateAndTimeViewMode
        ? await extractCalendarSelections({
            session: flowAPI.controllerConfig.platformAPIs.storage.session,
          })
        : undefined;
      const [
        queryServicesResponse,
        getBusinessInfoResponse,
        rescheduleBookingDetails,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
      ] = await Promise.all([
        calendarApi.queryServices({
          onError,
          calendarSelections,
          isDateAndTimeViewMode,
        }),
        calendarApi.getBusinessInfo(),
        calendarApi.getBookingDetails({
          onError: (type: EmptyStateType) => {
            if (!isAnonymousCancellationFlow) {
              onError(type);
            }
          },
        }),
        isPricingPlanInstalledUtils(flowAPI.controllerConfig.wixCodeApi).catch(
          () => false,
        ),
        isMemberAreaInstalledUtils({
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        }).catch(() => false),
      ]);

      servicesInView = queryServicesResponse?.services || [];

      const allPurchasedPricingPlans =
        (isCalendarPagePreset || isCalendarWidgetPreset) &&
        (!calendarSelections || calendarSelections.services.length === 1)
          ? await calendarApi.getPurchasedPricingPlans({
              currentUser,
              service: servicesInView.length ? servicesInView[0] : undefined,
            })
          : [];

      initialState = createInitialState({
        wixCodeApi: controllerConfig.wixCodeApi,
        servicesInView,
        rescheduleBookingDetails,
        initialErrors,
        allPurchasedPricingPlans,
        isPricingPlanInstalled,
        isUserLoggedIn: currentUser.loggedIn,
        servicesVariants: queryServicesResponse?.servicesVariants,
        experiments,
        calendarSelections,
      });
      calendarContext = await createCalendarContext({
        flowAPI,
        businessInfo: getBusinessInfoResponse,
        calendarApi,
        initialState,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
        settingsParams,
        preset,
        calendarSelections,
        reloadWidget: pageReady,
        isDateAndTimeViewMode,
        subscriptions,
      });
      const { biLogger } = calendarContext;
      const referralInfo = getUrlQueryParamValue(
        controllerConfig.wixCodeApi,
        BookingsQueryParams.REFERRAL,
      );
      void biLogger.report(
        bookingsUoUFlowWidgetLoadSrc16Evid32({
          widget_name: PresetToWidgetNameMap[preset],
          referralInfo,
        }),
      );

      if (
        experiments.enabled('specs.bookings.dynamicDurationUoU') &&
        isCalendarPagePreset &&
        !flowAPI.environment.isSSR &&
        !isDateAndTimeViewMode &&
        servicesInView.some(isServiceWithDynamicDuration)
      ) {
        redirectToDateAndTimeViewMode({
          flowAPI,
          servicesInView,
          serviceVariantsMap: initialState.serviceVariantsMap,
        });
      }

      const { onStateChange, render, controllerActions, setState } =
        await createControlledComponent<
          CalendarState,
          CalendarActions,
          CalendarViewModel,
          CalendarContext
        >({
          controllerConfig,
          initialState,
          viewModelFactory: createMemoizedCalendarViewModelFactory(
            shouldUseCalendarDummyViewModel,
          ),
          actionsFactory: createCalendarActions,
          context: calendarContext,
        });
      rerender = render;
      if (
        isAnonymousCancellationFlow &&
        !controllerConfig.wixCodeApi.user.currentUser.loggedIn &&
        !flowAPI.environment.isSSR &&
        getUrlQueryParamValue(
          flowAPI.controllerConfig.wixCodeApi,
          BookingsQueryParams.BOOKING_ID,
        )
      ) {
        setTimeout(async () => {
          try {
            // @ts-expect-error
            await controllerConfig.wixCodeApi.user.promptLogin();
          } catch (e) {
            //
          }

          const updatedRescheduleBookingDetails =
            await calendarApi.getBookingDetails({
              onError,
            });
          setState({
            rescheduleBookingDetails: updatedRescheduleBookingDetails,
          });
        }, 10);
      }

      if (isCalendarPagePreset) {
        const seoData = queryServicesResponse?.services?.length
          ? queryServicesResponse?.services[0]
          : undefined;

        await flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
          itemType: ITEM_TYPES.BOOKINGS_CALENDAR,
          itemData: seoData as any,
        });
      }

      if (!flowAPI.environment.isSSR) {
        onStateChange((state) => {
          biLogger.update(state, calendarContext);
        });
      }

      if (isCalendarPagePreset || isCalendarWidgetPreset) {
        flowAPI.controllerConfig.wixCodeApi.user.onLogin(
          controllerActions.onUserLoggedIn,
        );
      }

      const serviceNotFound = initialState.initialErrors.includes(
        EmptyStateType.SERVICE_NOT_FOUND,
      );

      if (serviceNotFound) {
        void biLogger.report(
          bookingsCalendarErrorMessages({
            errorMessage: 'SERVICE_NOT_FOUND',
          }),
        );
      }

      if (
        experiments.enabled('specs.bookings.dynamicDurationUoU') &&
        isDateAndTimeViewMode &&
        controllerConfig.platformAPIs.storage.session.getItem(
          AUTO_OPEN_PREFERENCES_MODAL_STORAGE_KEY,
        ) === 'true'
      ) {
        controllerActions.openServicesPreferencesModal();
      }

      flowAPI.fedops.interactionEnded(
        isUseBookingsViewerCacheEnabled
          ? 'calendar-pageReady-useBookingsViewerCache-true'
          : 'calendar-pageReady-useBookingsViewerCache-false',
      );
    };

    if (isCalendarPagePreset) {
      controllerConfig.wixCodeApi.location.onChange(() => pageReady());
    }

    return {
      pageReady,
      exports() {
        return {
          onNextClicked(
            overrideCallback: CalendarContext['subscriptions']['onNextClicked'],
          ) {
            subscriptions.onNextClicked = overrideCallback;
          },
        };
      },
      async updateConfig($w, newConfig) {
        const updatedPublicData = newConfig.publicData.COMPONENT || {};
        const isCalendarLayoutChanged =
          publicData.calendarLayout !== updatedPublicData.calendarLayout;
        const isLocationsSelectionChanged =
          publicData.selectedLocations !== updatedPublicData.selectedLocations;
        const isCategoriesSelectionChanged =
          publicData.selectedCategories !==
          updatedPublicData.selectedCategories;
        const isServiceSelectedChanged =
          publicData.selectedService !== updatedPublicData.selectedService;
        const isSlotsAvailabilityChanged =
          publicData.slotsAvailability !== updatedPublicData.slotsAvailability;
        publicData = updatedPublicData;

        if (
          !isCalendarPagePreset &&
          (isLocationsSelectionChanged ||
            isCategoriesSelectionChanged ||
            isServiceSelectedChanged ||
            isSlotsAvailabilityChanged)
        ) {
          pageReady();
        } else {
          await rerender({
            resetState: isCalendarLayoutChanged,
          });
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(
  Preset.CALENDAR_PAGE,
  calendarSettingsParams,
);

const isDateAndTimeMode = async ({
  flowAPI,
  preset,
}: {
  flowAPI: ControllerFlowAPI;
  preset: Preset;
}) => {
  if (!isCalendarPage(preset)) {
    return false;
  }

  const serviceSlug = await getServiceSlug({
    wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
    pageName: CALENDAR_PAGE_URL_PATH_PARAM,
  });

  return serviceSlug === ReservedSlug.DATE_AND_TIME;
};
