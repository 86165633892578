import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { Optional } from '../../../../types/types';
import { isCalendarPage } from '../../../../utils/presets';

export type EmptyStateViewModel = {
  title: string;
  subtitle: string;
};

export enum EmptyStateType {
  SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
  BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  GET_BOOKING_DETAILS_ERROR = 'GET_BOOKING_DETAILS_ERROR',
  GET_BOOKING_DETAILS_ACCESS_DENIED = 'GET_BOOKING_DETAILS_ACCESS_DENIED',
}

export const memoizedEmptyStateViewModel: MemoizedViewModalFactory<
  Optional<EmptyStateViewModel>
> = {
  dependencies: {
    state: ['initialErrors'],
  },
  createViewModel: createEmptyStateViewModel,
};

export function createEmptyStateViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<EmptyStateViewModel> {
  const { initialErrors } = state;
  const { t, preset } = context;
  const errorType = initialErrors[0];
  const isWidget = !isCalendarPage(preset);

  switch (errorType) {
    case EmptyStateType.SERVICE_NOT_FOUND:
      const subtitle = isWidget
        ? t('app.empty-state.no-services.subtitle')
        : t('app.empty-state.no-service.subtitle');
      return {
        title: t('app.empty-state.no-service.title'),
        subtitle,
      };
    case EmptyStateType.SERVER_ERROR:
      return {
        title: t('app.empty-state.server-error.title'),
        subtitle: t('app.empty-state.server-error.subtitle'),
      };
    case EmptyStateType.BOOKING_NOT_FOUND:
    case EmptyStateType.GET_BOOKING_DETAILS_ERROR:
      return {
        title: t('app.empty-state.booking-details-error.title'),
        subtitle: t('app.empty-state.booking-details-error.subtitle'),
      };
    case EmptyStateType.GET_BOOKING_DETAILS_ACCESS_DENIED:
      return {
        title: t('app.empty-state.booking-details-access-denied.title'),
        subtitle: t('app.empty-state.booking-details-access-denied.subtitle'),
      };
  }
}
