import { TFunction } from '../../../components/BookingCalendar/controller';

export const getNumberOfAvailableSlotsAnnouncement = ({
  numberOfAvailableSlots,
  t,
}: {
  numberOfAvailableSlots: number;
  t: TFunction;
}): string => {
  return numberOfAvailableSlots
    ? t('app.time-picker.accessibility.announcement.available-time-slots', {
        timeSlotsCount: numberOfAvailableSlots,
      })
    : t('app.time-picker.accessibility.announcement.no-available-time-slots');
};
