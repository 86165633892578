import {
  MoneyDto,
  OfferedAsType,
  PaymentType,
  Service,
  ServiceLocation,
  ServiceLocationType,
  ServiceType,
} from '@wix/bookings-uou-types';
import {
  Location as LocationV2,
  LocationType as LocationV2Type,
  Money,
  Payment as PaymentV2,
  PaymentOptions,
  RateType as RateTypeV2,
  Service as ServiceV2,
  ServiceType as ServiceTypeV2,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  GetServiceResponse,
  Schedule,
  ScheduleStatus,
} from '@wix/ambassador-services-catalog-server/types';

export const mapServiceToServiceV2 = (
  service: Service,
  catalogService: GetServiceResponse,
): ServiceV2 => {
  const activeSchedule = catalogService.schedules?.filter(
    (schedule) => schedule.status === ScheduleStatus.CREATED,
  );
  return {
    id: service.id,
    category: {
      id: service.info.categoryId,
      name: service.info.categoryName,
    },
    name: service.info.name,
    mainSlug: {
      name: service.info.slugs[0]!,
    },
    staffMembers: service.staffMembers.map(({ id: staffMemberId, name }) => ({
      name,
      staffMemberId,
    })),
    staffMemberIds: service.staffMembers.map(({ id }) => id),
    schedule: {
      availabilityConstraints: {
        sessionDurations: [service.info.duration!],
      },
    },
    bookingPolicy: mapServicePolicy(service.policy),
    type: mapServiceTypeToV2(service.info.type),
    locations: service.locations.map(mapServiceLocationToV2),
    payment: mapServicePaymentToV2(service.payment, activeSchedule?.[0]),
    onlineBooking: mapServiceOnlineBookingToV2(service),
    tagLine: service.info.tagline,
    conferencing: {
      enabled: service.includeConferenceOption,
    },
  };
};

const mapServicePolicy = (
  policy: Service['policy'],
): ServiceV2['bookingPolicy'] => {
  const { minutesBeforeSlotBookWindowStart, minutesBeforeSlotBookWindowEnd } =
    policy;
  return {
    limitEarlyBookingPolicy: {
      enabled: minutesBeforeSlotBookWindowStart !== undefined,
      earliestBookingInMinutes: minutesBeforeSlotBookWindowStart,
    },
    limitLateBookingPolicy: {
      enabled: minutesBeforeSlotBookWindowEnd !== undefined,
      latestBookingInMinutes: minutesBeforeSlotBookWindowEnd,
    },
    participantsPolicy: {
      enabled: policy.maxParticipantsPerBook !== undefined,
      maxParticipantsPerBooking: policy.maxParticipantsPerBook,
    },
  };
};

const mapMoneyDtoToMoneyV2 = (money: MoneyDto): Money => ({
  currency: money.currency,
  value: money.price.toString(),
  formattedValue: money.formattedValue,
});

const mapServicePaymentToV2 = (
  payment: Service['payment'],
  activeSchedule?: Schedule,
): PaymentV2 => {
  const { paymentDetails, offeredAs, pricingPlanInfo } = payment;
  const pricingPlanIds = pricingPlanInfo?.pricingPlans.map(
    (pricingPlan) => pricingPlan.id,
  );
  const {
    isVariedPricing,
    minPrice,
    maxPrice,
    isFree,
    currency,
    price,
    priceText,
    paymentType,
  } = paymentDetails;

  const options: PaymentOptions = {
    online: [PaymentType.BOTH, PaymentType.ONLINE].includes(paymentType),
    inPerson: [PaymentType.BOTH, PaymentType.OFFLINE].includes(paymentType),
  };

  if (isVariedPricing) {
    const defaultPrice = activeSchedule?.rate?.defaultVariedPrice;
    return {
      pricingPlanIds,
      rateType: RateTypeV2.VARIED,
      varied: {
        minPrice: mapMoneyDtoToMoneyV2(minPrice!),
        maxPrice: mapMoneyDtoToMoneyV2(maxPrice!),
        defaultPrice: {
          value: defaultPrice?.amount,
          currency: defaultPrice?.currency,
        },
      },
      options,
    };
  }

  if (price === 0 || isFree || !offeredAs.includes(OfferedAsType.ONE_TIME)) {
    return {
      pricingPlanIds,
      rateType: RateTypeV2.NO_FEE,
    };
  }

  if (priceText) {
    return {
      pricingPlanIds,
      rateType: RateTypeV2.CUSTOM,
      custom: {
        description: priceText,
      },
      options,
    };
  }

  return {
    pricingPlanIds,
    rateType: RateTypeV2.FIXED,
    fixed: {
      price: {
        currency,
        value: price.toString(),
      },
    },
    options,
  };
};

export const mapServiceLocationToV2 = (
  location: ServiceLocation,
): LocationV2 => {
  const { type, businessLocation, locationText } = location;

  if (type === ServiceLocationType.CUSTOM) {
    return {
      type: LocationV2Type.CUSTOMER,
    };
  }

  if (type === ServiceLocationType.OWNER_CUSTOM) {
    return {
      type: LocationV2Type.CUSTOM,
      calculatedAddress: {
        formattedAddress: locationText,
      },
      custom: {
        address: {
          formattedAddress: locationText,
        },
      },
    };
  }

  return {
    type: LocationV2Type.BUSINESS,
    id: businessLocation?.id,
    business: {
      id: businessLocation?.id,
      name: businessLocation?.name,
    },
  };
};

export const mapServiceTypeToV2 = (
  serviceType: ServiceType,
): ServiceV2['type'] => {
  if (serviceType === ServiceType.COURSE) {
    return ServiceTypeV2.COURSE;
  }

  if (serviceType === ServiceType.GROUP) {
    return ServiceTypeV2.CLASS;
  }

  return ServiceTypeV2.APPOINTMENT;
};

const mapServiceOnlineBookingToV2 = (
  service: Service,
): ServiceV2['onlineBooking'] => ({
  requireManualApproval: service.policy.isPendingApprovalFlow,
});
