import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createDummyService } from './dummyServices';
import { CalendarCatalogData } from '../types';

export const DUMMY_SERVICE_IDS = ['DUMMY_ID_1', 'DUMMY_ID_2'];

export const createDummyActiveFeatures = () => {
  return {
    applicableForCourse: true,
    bookingsStaffLimit: 1,
    applicableForServiceList: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForSmsReminders: true,
  };
};

export const createDummyBusinessInfo = (flowAPI: ControllerFlowAPI) => {
  const { t, config } = flowAPI.translations;

  return {
    language: config.language,
    currency: t('dummy-content.business.currency'),
    timeZone: t('dummy-content.business.timeZone'),
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: t('dummy-content.business.regionalSettingsLocale'),
  };
};

export const createDummyCatalogData = (
  flowAPI: ControllerFlowAPI,
  serviceIds: string[] = DUMMY_SERVICE_IDS,
): CalendarCatalogData => {
  return {
    services: serviceIds.map((id) => createDummyService(id, flowAPI)),
    businessInfo: createDummyBusinessInfo(flowAPI),
    activeFeatures: createDummyActiveFeatures(),
  };
};
