import { isServiceVariantWithStaff } from '../dynamicPricing/dynamicPricing';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  isServiceAutoAssignResourceAllowed,
  isServiceBookOnlineEnabled,
  isServicePendingApprovalFlow,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { GetActiveFeaturesResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';

export const isPendingApprovalFlow = (services: Service[]) => {
  return services.some((service) => isServicePendingApprovalFlow(service));
};

export const isCalendarFlow = (service: Service) =>
  !(service.type === ServiceType.COURSE) && isServiceBookOnlineEnabled(service);

export function isBookingsEnabled({
  servicesTypes,
  activeFeatures,
  isTemplateMode,
}: {
  servicesTypes: ServiceType[];
  activeFeatures: GetActiveFeaturesResponse;
  isTemplateMode: boolean;
}): boolean {
  if (isTemplateMode) {
    return true;
  }

  return servicesTypes.every((serviceType) => {
    switch (serviceType) {
      case ServiceType.CLASS:
        return !!activeFeatures.applicableForGroups;
      case ServiceType.APPOINTMENT:
        return !!activeFeatures.applicableForIndividual;
      default:
        return true;
    }
  });
}

export const canAutoAssignStaff = ({
  serviceVariantsMap,
  servicesInView,
  selectableSlotsAtSelectedTime = [],
  filterOptions,
}: Pick<
  CalendarState,
  | 'selectableSlotsAtSelectedTime'
  | 'servicesInView'
  | 'serviceVariantsMap'
  | 'filterOptions'
>) => {
  const resources = new Set(
    selectableSlotsAtSelectedTime.map((slot) => slot.slot?.resource?.id),
  );
  return (
    !filterOptions[FilterTypes.STAFF_MEMBER].length &&
    resources.size > 1 &&
    servicesInView.every(
      (service) =>
        !isServiceVariantWithStaff(serviceVariantsMap[service.id!]) &&
        isServiceAutoAssignResourceAllowed(service),
    )
  );
};
