import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/build/cjs/types.impl';
import { CalendarState } from '../../components/BookingCalendar/controller';
import {
  differencesBetweenTwoDates,
  TimeUnits,
} from '../dateAndTime/dateAndTime';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FedopsInteractions } from './consts';

export async function fedopsQueryAvailabilityInteractionStart({
  state,
  request,
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
  state: CalendarState;
  request: QueryAvailabilityRequest;
}) {
  const queryAvailabilityRequest: QueryAvailabilityRequest = request;
  const from = queryAvailabilityRequest.query.filter?.from;
  const to = queryAvailabilityRequest.query.filter?.to;
  const rangeInDays = differencesBetweenTwoDates(from, to, TimeUnits.day);
  const service = state.servicesInView[0];

  const numberOfLocations =
    queryAvailabilityRequest.query.filter?.['location.businessLocation.id']
      ?.length || service.locations?.length;

  const numberOfStaffMembers =
    queryAvailabilityRequest.query.filter?.resourceId?.length ||
    service.staffMemberIds?.length;

  flowAPI.fedops.interactionStarted(FedopsInteractions.queryAvailability, {
    customParams: {
      rangeInDays,
      numberOfLocations,
      numberOfStaffMembers,
      serviceType: service.type,
    },
  });
}

export async function fedopsQueryAvailabilityInteractionEnd({
  response,
  error,
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
  response?: QueryAvailabilityResponse;
  error?: string;
}) {
  flowAPI.fedops.interactionEnded(FedopsInteractions.queryAvailability, {
    customParams: {
      numberOfAvailabilityEntries: response?.availabilityEntries?.length,
      error,
    },
  });
}
