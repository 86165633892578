import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { formatRfcTimeStringToTimeSlotView } from '../../../../utils/dateAndTime/dateAndTime';
import { TimeSlotAvailabilityStatus } from '../../../../utils/timeSlots/timeSlots';
import { MemoizedViewModalFactory } from '../viewModel';
import { TimeSlotComponent, TriggeredByOptions } from '../../../../types/types';
import { isWeeklyTimeSlotsLayout } from '../../../../utils/layouts';
import { isLightBox } from '@wix/bookings-catalog-calendar-viewer-utils';

export interface TimeSlotStatus extends TimeSlotAvailabilityStatus {
  selected: boolean;
}

export interface TimeSlot {
  formattedStartTime: string;
  rfcStartTime: string;
  status: TimeSlotStatus;
  ariaLabel: string;
}

export type TimeSlotsSelectionViewModel = {
  timeSlots: TimeSlot[];
  shouldLimitNumberOfTimeSlotsDisplayed: boolean;
  maxNumberOfTimeSlotsToDisplay: number;
  showAllButtonText: string;
  waitlistText: string;
  highlightedSlotDetails: {
    shouldFocusOnRender: boolean;
    slotIndex?: number;
  };
  timeSlotComponent: TimeSlotComponent;
  appendToViewPort: boolean;
};

type TimeSlotsSelectionViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
  shouldHighlightedSlotDetailsOnRender: boolean;
};

export const memoizedTimeSlotsSelectionViewModel: MemoizedViewModalFactory<TimeSlotsSelectionViewModel> =
  {
    dependencies: {
      state: ['selectedTime', 'selectedDateTrigger'],
      settings: [
        'waitlistIndication',
        'limitTimeSlotsDisplay',
        'maxTimeSlotsDisplayedPerDay',
        'loadMoreTimeSlots',
        'calendarLayout',
        'timeSlotComponent',
      ],
    },
  };

export function createTimeSlotsSelectionViewModel({
  timeSlotsAvailabilityStatuses,
  shouldHighlightedSlotDetailsOnRender,
  state,
  context,
}: TimeSlotsSelectionViewModelParams): TimeSlotsSelectionViewModel {
  const { selectedTime } = state;
  const {
    languageSettings,
    t,
    settings,
    settingsParams,
    getContent,
    isMobile,
    flowAPI,
  } = context;
  const locale = languageSettings!.dateRegionalSettingsLocale;

  const waitlistText = getContent({
    settingsParam: settingsParams.waitlistIndication,
    translationKey: 'app.settings.defaults.waitlist',
  });
  const timeSlots: TimeSlot[] = [];
  timeSlotsAvailabilityStatuses.forEach(
    (timeSlotStatus: TimeSlotAvailabilityStatus, rfcStartTime: string) => {
      const formattedStartTime = rfcStartTime
        ? formatRfcTimeStringToTimeSlotView(rfcStartTime, locale)
        : '';
      const isTimeSelected = rfcStartTime === selectedTime;
      const ariaLabel = getTimeSlotAriaLabel(
        timeSlotStatus,
        formattedStartTime,
        t,
        waitlistText,
      );

      timeSlots.push({
        rfcStartTime,
        formattedStartTime,
        status: { ...timeSlotStatus, selected: isTimeSelected },
        ariaLabel,
      });
    },
  );

  const highlightedSlotDetails =
    !isMobile &&
    shouldHighlightedSlotDetailsOnRender &&
    state.selectedDateTrigger ===
      TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK
      ? {
          shouldFocusOnRender: true,
          slotIndex: 0,
        }
      : {
          shouldFocusOnRender: false,
        };

  let showAllButtonText = '';
  if (isWeeklyTimeSlotsLayout(settings, settingsParams)) {
    showAllButtonText = getContent({
      settingsParam: settingsParams.loadMoreTimeSlots,
      translationKey: 'app.settings.defaults.week-availability.show-all',
    });
  } else {
    showAllButtonText = getContent({
      settingsParam: settingsParams.loadMoreTimeSlots,
      translationKey: 'app.settings.defaults.time-picker.show-all',
    });
  }

  const calculateShouldLimitNumberOfTimeSlots = () => {
    if (
      settings.get(settingsParams.timeSlotComponent) ===
      TimeSlotComponent.DROPDOWN
    ) {
      return false;
    }
    return settings.get(settingsParams.limitTimeSlotsDisplay);
  };

  return {
    highlightedSlotDetails,
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed:
      calculateShouldLimitNumberOfTimeSlots(),
    maxNumberOfTimeSlotsToDisplay: settings.get(
      settingsParams.maxTimeSlotsDisplayedPerDay,
    ),
    showAllButtonText,
    waitlistText,
    timeSlotComponent: settings.get(settingsParams.timeSlotComponent),
    appendToViewPort: !isLightBox(flowAPI.controllerConfig.wixCodeApi),
  };
}

const getTimeSlotAriaLabel = (
  timeSlotStatus: TimeSlotAvailabilityStatus,
  formattedStartTime: string,
  t: TFunction,
  waitlistText: string,
): string => {
  if (timeSlotStatus.withWaitingList) {
    return t('app.time-picker.accessibility.time-slot-with-waitlist', {
      time: formattedStartTime,
      waitlist: waitlistText,
    });
  }
  if (timeSlotStatus.tooLateToBookAllSlots || timeSlotStatus.allSlotsAreFull) {
    return t(
      'app.time-picker.accessibility.time-slot-closed-for-registration',
      { time: formattedStartTime },
    );
  }
  if (timeSlotStatus.tooEarlyToBookAllSlots) {
    return t(
      'app.time-picker.accessibility.time-slot-not-open-yet-for-registration',
      { time: formattedStartTime },
    );
  }
  return formattedStartTime;
};
