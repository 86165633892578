import {
  LocationType,
  RateType,
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const DUMMY_SERVICE_IDS = ['DUMMY_ID_1', 'DUMMY_ID_2'];

export const isDummyServices = (services: Service[]) =>
  services.some((service) => DUMMY_SERVICE_IDS.includes(service.id!));

export const createDummyServices = (
  flowAPI: ControllerFlowAPI,
  serviceIds: string[] = DUMMY_SERVICE_IDS,
) => {
  return serviceIds.map((id) => createDummyService(id, flowAPI));
};

export const createDummyService = (
  serviceId: string,
  flowAPI: ControllerFlowAPI,
) => {
  const { t } = flowAPI.translations;

  return {
    id: serviceId,
    name: t('dummy-content.service.name'),
    tagLine: t('dummy-content.service.tagline'),
    type: ServiceType.APPOINTMENT,
    mainSlug: {
      name: t('dummy-content.service.name').replace(' ', '-').toLowerCase(),
    },
    category: {
      id: 'some-category-id',
      name: 'some-category-name',
    },
    media: {
      items: [],
    },
    locations: [
      {
        type: LocationType.BUSINESS,
        id: 'location-1-id',
        business: {
          id: 'location-1-id',
        },
      },
      {
        type: LocationType.BUSINESS,
        id: 'location-2-id',
        business: {
          id: 'location-2-id',
        },
      },
    ],
    staffMembers: [
      {
        staffMemberId: 'staff-1-id',
        name: t('dummy-content.service.staff'),
      },
      {
        staffMemberId: 'staff-2-id',
        name: t('dummy-content.service.staff-2'),
      },
    ],
    staffMemberIds: ['staff-1-id', 'staff-2-id'],
    defaultCapacity: 1,
    onlineBooking: {
      requireManualApproval: false,
    },
    payment: {
      options: {
        online: true,
        inPerson: false,
      },
      rateType: RateType.CUSTOM,
      custom: {
        description: t('dummy-content.service.price'),
      },
    },
    bookingPolicy: {
      limitEarlyBookingPolicy: {
        enabled: false,
      },
      limitLateBookingPolicy: {
        enabled: false,
      },

      participantsPolicy: {
        maxParticipantsPerBooking: 1,
      },
    },
  };
};
