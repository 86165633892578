import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { PolicyFormatter } from '@wix/bookings-uou-mappers';
import { TimeSlotAvailabilityStatus } from '../../../../utils/timeSlots/timeSlots';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { isWeeklyTimeSlotsLayout } from '../../../../utils/layouts';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  getServiceEarliestBookingInMinutes,
  getServiceLatestBookingInMinutes,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export const enum TimeSlotsNotificationType {
  ALL_SESSIONS_ARE_FULL = 'ALL_SESSIONS_ARE_FULL',
  ALL_SESSIONS_ARE_CLOSED = 'ALL_SESSIONS_ARE_CLOSED',
  ALL_SESSIONS_ARE_NOT_YET_OPEN = 'ALL_SESSIONS_ARE_NOT_YET_OPEN',
  SOME_SESSIONS_ARE_CLOSED = 'SOME_SESSIONS_ARE_CLOSED',
  SOME_SESSIONS_ARE_NOT_YET_OPEN = 'SOME_SESSIONS_ARE_NOT_YET_OPEN',
}

export type TimeSlotsNotificationViewModel = {
  notificationType?: TimeSlotsNotificationType;
  messageText?: string;
  ctaText?: string;
};

type TimeSlotsNotificationViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
};

export const memoizedTimeSlotsNotificationViewModel: MemoizedViewModalFactory<TimeSlotsNotificationViewModel> =
  {
    dependencies: {
      state: ['servicesInView'],
      settings: [
        'fullyBookedDateNotification',
        'goToNextAvailableDate',
        'calendarLayout',
      ],
    },
  };

function comparePolicyForMultipleServices(servicesInView: Service[]) {
  const isAllServicesAreSamePolicyBeforeBookWindowStart = servicesInView.every(
    (service) =>
      getServiceEarliestBookingInMinutes(service) ===
      getServiceEarliestBookingInMinutes(servicesInView[0]),
  );
  const isAllServicesAreSamePolicyBeforeBookWindowEnd = servicesInView.every(
    (service) =>
      getServiceLatestBookingInMinutes(service) ===
      getServiceLatestBookingInMinutes(servicesInView[0]),
  );

  return {
    isAllServicesAreSamePolicyBeforeBookWindowStart,
    isAllServicesAreSamePolicyBeforeBookWindowEnd,
  };
}

export function createTimeSlotsNotificationViewModel({
  timeSlotsAvailabilityStatuses,
  state,
  context,
}: TimeSlotsNotificationViewModelParams): Optional<TimeSlotsNotificationViewModel> {
  const { servicesInView } = state;
  const { getContent, t, settings, settingsParams } = context;

  const {
    isAllServicesAreSamePolicyBeforeBookWindowStart,
    isAllServicesAreSamePolicyBeforeBookWindowEnd,
  } = comparePolicyForMultipleServices(servicesInView);
  const { minutesBeforeSlotBookWindowStart, minutesBeforeSlotBookWindowEnd } =
    getFormattedPolicyLimits(servicesInView[0], t);
  const notificationOptions = getNotificationOptions(
    timeSlotsAvailabilityStatuses,
  );

  if (notificationOptions.allSessionsAreFull) {
    return {
      notificationType: TimeSlotsNotificationType.ALL_SESSIONS_ARE_FULL,
      messageText: getContent({
        settingsParam: settingsParams.fullyBookedDateNotification,
        translationKey:
          'app.settings.defaults.time-picker.notifications.all-sessions-are-full',
      }),
      ctaText: getContent({
        settingsParam: settingsParams.goToNextAvailableDate,
        translationKey:
          'app.settings.defaults.time-picker.go-to-next-available-day',
      }),
    };
  } else if (notificationOptions.allSessionsAreClosed) {
    const messageText = isAllServicesAreSamePolicyBeforeBookWindowEnd
      ? t('app.time-picker.notifications.all-sessions-are-closed', {
          duration: minutesBeforeSlotBookWindowEnd,
        })
      : t('app.time-picker.notifications.generic.all-sessions-are-closed');
    return {
      notificationType: TimeSlotsNotificationType.ALL_SESSIONS_ARE_CLOSED,
      messageText,
    };
  } else if (notificationOptions.allSessionsAreNotOpenYet) {
    const messageText = isAllServicesAreSamePolicyBeforeBookWindowStart
      ? t('app.time-picker.notifications.all-sessions-are-not-open-yet', {
          duration: minutesBeforeSlotBookWindowStart,
        })
      : t(
          'app.time-picker.notifications.generic.all-sessions-are-not-open-yet',
        );
    return {
      notificationType: TimeSlotsNotificationType.ALL_SESSIONS_ARE_NOT_YET_OPEN,
      messageText,
    };
  } else if (notificationOptions.someSessionsAreClosed) {
    const translationKey = isWeeklyTimeSlotsLayout(settings, settingsParams)
      ? 'app.time-picker.notifications.all-sessions-are-closed'
      : 'app.time-picker.notifications.some-sessions-are-closed';
    const messageText = isAllServicesAreSamePolicyBeforeBookWindowEnd
      ? t(translationKey, {
          duration: minutesBeforeSlotBookWindowEnd,
        })
      : t('app.time-picker.notifications.generic.all-sessions-are-closed');
    return {
      notificationType: TimeSlotsNotificationType.SOME_SESSIONS_ARE_CLOSED,
      messageText,
    };
  } else if (notificationOptions.someSessionsAreNotOpenYet) {
    const translationKey = isWeeklyTimeSlotsLayout(settings, settingsParams)
      ? 'app.time-picker.notifications.all-sessions-are-not-open-yet'
      : 'app.time-picker.notifications.some-sessions-are-not-open-yet';
    const messageText = isAllServicesAreSamePolicyBeforeBookWindowStart
      ? t(translationKey, {
          duration: minutesBeforeSlotBookWindowStart,
        })
      : t(
          'app.time-picker.notifications.generic.all-sessions-are-not-open-yet',
        );
    return {
      notificationType:
        TimeSlotsNotificationType.SOME_SESSIONS_ARE_NOT_YET_OPEN,
      messageText,
    };
  }
}

const getNotificationOptions = (
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>,
) => {
  const initialNotificationOptions = {
    allSessionsAreFull: true,
    allSessionsAreClosed: true,
    allSessionsAreNotOpenYet: true,
    someSessionsAreClosed: false,
    someSessionsAreNotOpenYet: false,
  };
  return Array.from(timeSlotsAvailabilityStatuses).reduce(
    (notificationOptions, [, currentTimeSlotStatus]) => {
      const { allSlotsAreFull, tooEarlyToBookAllSlots, tooLateToBookAllSlots } =
        currentTimeSlotStatus;
      const {
        allSessionsAreFull,
        allSessionsAreNotOpenYet,
        allSessionsAreClosed,
        someSessionsAreClosed,
        someSessionsAreNotOpenYet,
      } = notificationOptions;
      return {
        allSessionsAreFull: allSessionsAreFull && allSlotsAreFull,
        allSessionsAreClosed: allSessionsAreClosed && tooLateToBookAllSlots,
        allSessionsAreNotOpenYet:
          allSessionsAreNotOpenYet && tooEarlyToBookAllSlots,
        someSessionsAreClosed: someSessionsAreClosed || tooLateToBookAllSlots,
        someSessionsAreNotOpenYet:
          someSessionsAreNotOpenYet || tooEarlyToBookAllSlots,
      };
    },
    initialNotificationOptions,
  );
};

const getFormattedPolicyLimits = (service: Service, t: TFunction) => {
  const servicePolicyTranslations = {
    days: t('app.time-picker.notifications.policy.days'),
    day: t('app.time-picker.notifications.policy.day'),
    hours: t('app.time-picker.notifications.policy.hours'),
    hour: t('app.time-picker.notifications.policy.hour'),
    minutes: t('app.time-picker.notifications.policy.minutes'),
    minute: t('app.time-picker.notifications.policy.minute'),
    and: t('app.time-picker.notifications.policy.and'),
  };

  const policyFormatter = new PolicyFormatter(servicePolicyTranslations);

  return {
    minutesBeforeSlotBookWindowStart: policyFormatter.getPolicyLimit(
      getServiceEarliestBookingInMinutes(service)!,
    ),
    minutesBeforeSlotBookWindowEnd: policyFormatter.getPolicyLimit(
      getServiceLatestBookingInMinutes(service)!,
    ),
  };
};
