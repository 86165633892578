import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { Basket } from '@wix/bookings-services-preferences-modal';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DO_NOT_CARE_STAFF } from '../../constants/constants';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { CalendarState } from '../../components/BookingCalendar/controller';

export const redirectToDateAndTimeViewMode = ({
  flowAPI,
  servicesInView,
  serviceVariantsMap,
}: {
  flowAPI: ControllerFlowAPI;
  servicesInView: Service[];
  serviceVariantsMap: CalendarState['serviceVariantsMap'];
}) => {
  const { wixCodeApi, platformAPIs } = flowAPI.controllerConfig;
  const firstService = servicesInView[0];
  const firstServiceLocation = firstService.locations?.[0];

  const timezoneFromQueryParam = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.TIMEZONE,
  );
  const referralFromQueryParam = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.REFERRAL,
  );
  const resourceFromQueryParam = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.RESOURCE,
  );
  const locationFromQueryParam = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.LOCATION,
  );

  const basket: Basket = {
    location: {
      type: firstServiceLocation?.type!,
      value:
        locationFromQueryParam &&
        firstService.locations?.some(
          ({ business }) => business?.id === locationFromQueryParam,
        )
          ? locationFromQueryParam
          : firstServiceLocation?.business?.id ||
            firstServiceLocation?.custom?.address?.formattedAddress! ||
            '',
    },
    selectedServicesPreferences: servicesInView.map((service) => ({
      service,
      resources: {
        staff: [
          service.staffMemberIds?.includes(resourceFromQueryParam)
            ? resourceFromQueryParam
            : service.staffMemberIds?.length === 1
            ? service.staffMemberIds[0]
            : DO_NOT_CARE_STAFF,
        ],
      },
      variant: serviceVariantsMap[service.id!]?.variants?.values?.[0],
    })),
  };

  const autoOpenPreferencesModal = servicesInView.some(
    (service) => service.locations?.length! > 1,
  );

  navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
    serviceSlugOrBasket: basket,
    navigationContext: {
      timezone: timezoneFromQueryParam,
      referral: referralFromQueryParam,
      autoOpenPreferencesModal,
    },
  });
};
