import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getLocalTimezone } from '../../../../utils/dateAndTime/dateAndTime';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  getTimeZone,
  canClientChangeTimezone,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';

export type TimezoneSelectionViewModel = {
  selectableTimezones: string[];
  selectedTimezone: string;
  timezoneLabel: string;
  timezoneLocale: string;
};

export const memoizedTimezoneSelectionViewModel: MemoizedViewModalFactory<
  Optional<TimezoneSelectionViewModel>
> = {
  dependencies: {
    state: ['selectedTimezone'],
  },
  createViewModel: createTimezoneSelectionViewModel,
};

export function createTimezoneSelectionViewModel({
  context,
  state,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<TimezoneSelectionViewModel> {
  const { selectedTimezone } = state;
  const { businessInfo, t, reportError, languageSettings } = context;

  const locale = languageSettings!.dateRegionalSettingsLocale!;

  try {
    const selectableTimezones = getSelectableTimezones(businessInfo!);
    return {
      selectableTimezones,
      selectedTimezone: selectedTimezone!,
      timezoneLabel: t('app.timezone-selection.timezone-label'),
      timezoneLocale: locale,
    };
  } catch (e) {
    reportError(e as string | Error);
  }
}

function getSelectableTimezones(
  businessInfo: GetBusinessResponse | GetAggregatedInfoResponse,
): string[] {
  if (canClientChangeTimezone(businessInfo)) {
    return [getTimeZone(businessInfo) || '', getLocalTimezone()];
  }
  return [];
}
