import {
  BookingPreference,
  SelectedBookingPreference,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { AddError } from '../addError/addError';

export const updateCalendarStateWithPreferencesErrors = (
  bookingPreferences: BookingPreference[],
  addErrorAction: AddError,
  selectedBookingPreferences?: SelectedBookingPreference[],
) => {
  bookingPreferences.forEach((bookingPreference: BookingPreference) => {
    const isMultipleOptions = bookingPreference.options.length > 1;

    const isOptionSelected = selectedBookingPreferences?.some(
      (selectedPreference: SelectedBookingPreference) =>
        selectedPreference.key === bookingPreference.key,
    );

    const isMultipleChoicesHasNoParticipants =
      bookingPreference.isMultipleChoices &&
      bookingPreference.options.every(
        ({ numberOfParticipants }) => numberOfParticipants! === 0,
      );

    if (
      (isMultipleOptions && !isOptionSelected) ||
      isMultipleChoicesHasNoParticipants
    ) {
      addErrorAction(bookingPreference.error.key);
    }
  });
};
